import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import HeaderComponent from "./components/HeaderComponent";
import SliderComponent from "./components/SliderComponent";
import FooterComponent from "./components/FooterComponent";
import CommonScript from "./components/CommonScript";
import CommonStyle from "./components/CommonStyle";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import News from "./pages/News";
import ContactUs from "./pages/ContactUs";
import NavigationHandler from "./components/NavigationHandler";
import PageNotFound from "./pages/PageNotFound";
import NewsDetail from "./pages/NewsDetail";
import { HelmetProvider } from "react-helmet-async";
import Video from "./pages/Video";
const helmetContext = {};

function App() {
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Router>
          <NavigationHandler />
          <HeaderComponent />
          <div className="App">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    {/* <SliderComponent /> */}
                    <Video videoId="Q7cFAC_OL14" />
                  </>
                }
              />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/news" element={<News />} />
              <Route path="/news-detail/:slug" element={<NewsDetail />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </Router>
        <CommonScript />
      </HelmetProvider>
    </>
  );
}

export default App;
