import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { manageMainElementVisibility } from "../utils";

interface BackgroundVideoProps {
  videoId: string;
}

const Video: React.FC<BackgroundVideoProps> = ({ videoId }) => {
  const location = useLocation();

  useEffect(() => {
    manageMainElementVisibility(location.pathname);
  }, [location.pathname]);
  return (
    <>
      <div style={styles.container}>
        <iframe
          style={styles.video}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&loop=1&playlist=${videoId}&controls=0&showinfo=0&modestbranding=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
};

const styles = {
  container: {
    position: "relative" as "relative",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },
  video: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    width: "100vw",
    height: "56.25vw",
    minHeight: "100vh",
    minWidth: "177.77vh",
    transform: "translate(-50%, -50%)",
    pointerEvents: "none" as "none",
  },
};

export default Video;
