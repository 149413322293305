import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const HeaderComponent: React.FC = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("/"); // Default active link

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
    closeNav();
    window.scrollTo(0, 0);
  };

  const customScroll = (el: any) => {
    const yOffset = -80; // Adjust this value as needed
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const getLinkClass = (path: string) => `nav-link scrollto`;

  const openNav = () => setNavOpen(true);
  const closeNav = () => {
    setNavOpen(false);
    setDropdownOpen(false);
  };

  const overlayStyle = { height: navOpen ? "100%" : "0%" };

  return (
    <header id="header" className="header fixed-top">
      <style>
        {`
          @media (min-width: 992px) {
            .navbar-toggler {
              display: none;
            }
          }
          /* Override expanding of dropdown menu */
          .dropdown-menu a {
            padding: 12px 9px !important;
            font-weight: normal !important;
          }
        `}
      </style>
      <div className="container d-flex align-items-center head-br-btm">
        <NavLink to="/" className="logo me-auto" onClick={() => handleLinkClick("/")}>
          <img src="/assets/img/logo.png" alt="" className="img-fluid" />
        </NavLink>

        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>
              <NavLink className={getLinkClass("/")} to="/" onClick={() => handleLinkClick("/")}>
                <span className="link-text">Home</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink className={getLinkClass("/about")} to="/about" onClick={() => handleLinkClick("/about")}>
                <span className="NavLink-text">About Us</span>
              </NavLink>
            </li> */}
            {/* <NavLink className={getLinkClass("/services")} to="/services" onClick={() => handleLinkClick("/services")}>
                <span className="NavLink-text">Services</span>
              </NavLink> */}

            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-toggle="dropdown"
                onClick={(e) => e.preventDefault()}
              >
                Partner with Us <i className="bi bi-caret-down-fill"></i>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <HashLink className="dropdown-item" to="/services#Warehousing" scroll={customScroll}>
                    Warehousing
                  </HashLink>
                </li>
                <li>
                  <HashLink className="dropdown-item" to="/services#In-Plant Operations" scroll={customScroll}>
                    In-Plant Operations
                  </HashLink>
                </li>
                <li>
                  <HashLink className="dropdown-item" to="/services#Last Mile & Distribution" scroll={customScroll}>
                    Last Mile & Distribution
                  </HashLink>
                </li>
                <li>
                  <HashLink className="dropdown-item" to="/services#Packaging" scroll={customScroll}>
                    Packaging
                  </HashLink>
                </li>
                <li>
                  <HashLink className="dropdown-item" to="/services#E-Commerce" scroll={customScroll}>
                    E-Commerce
                  </HashLink>
                </li>
                <li>
                  <HashLink className="dropdown-item" to="/services#Project Management" scroll={customScroll}>
                    Project Management
                  </HashLink>
                </li>
                <li>
                  <HashLink className="dropdown-item" to="/services#Supply Chain Consultancy" scroll={customScroll}>
                    Supply Chain Consultancy
                  </HashLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink className={getLinkClass("/news")} to="/news" onClick={() => handleLinkClick("/news")}>
                <span className="NavLink-text">Blogs</span>
              </NavLink>
            </li>
            <li>
              {/* Use HashLink to scroll directly to the FAQs section */}
              <HashLink className={getLinkClass("/faqs")} to="/#faq" onClick={() => handleLinkClick("/faqs")} scroll={customScroll}>
                <span className="NavLink-text">FAQs</span>
              </HashLink>
            </li>
            {/* <li>
              <NavLink className={getLinkClass("/contact-us")} to="/contact-us" onClick={() => handleLinkClick("/contact-us")}>
                <span className="NavLink-text">Contact</span>
              </NavLink>
            </li> */}
          </ul>
          <div className="navbar-toggler" onClick={openNav} data-bs-toggle="collapse" data-bs-target="#main_nav">
            <span className="navbar-toggler-icon"></span>
          </div>
        </nav>
      </div>

      {/* <!------ OVERLAY MENU START ------> */}
      <div id="myNav" className="overlayp" style={overlayStyle}>
        <div className="menu_lft">
          <div className="log_loc">
            <div className="men_logo">
              {/* <a href="/">
                <img src="/assets/img/logo.png" alt="" />
              </a> */}
              <NavLink to="/" onClick={() => handleLinkClick("/")}>
                <img src="/assets/img/logo.png" alt="" />
              </NavLink>
            </div>
          </div>
        </div>

        <div className="menu_rgt">
          {/* <!-- Button to close the overlay navigation --> */}
          <a href="#" className="closebtn" onClick={closeNav}>
            &times;
          </a>

          {/* <!-- Overlay content --> */}
          <div className="overlayp-content">
            <ul>
              <li>
                <NavLink to="/" onClick={() => handleLinkClick("/")}>
                  Home
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/about" onClick={() => handleLinkClick("/")}>
                  About Us
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/services" onClick={() => handleLinkClick("/")}>
                  Services
                </NavLink>
              </li> */}
              <li className="dropdown">
                <a
                  href="#"
                  className="dropdown-toggle"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded={isDropdownOpen ? "true" : "false"} // Track dropdown state
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default behavior of the anchor tag
                    setDropdownOpen(!isDropdownOpen); // Toggle dropdown state
                  }}
                >
                  Partner with Us <i className="bi bi-caret-down-fill"></i>
                </a>
                {isDropdownOpen && (
                  <ul className="dropdown-menu show">
                    <li className="overlay-list-item">
                      <HashLink className="dropdown-item" to="/services#Warehousing" onClick={(e) => handleLinkClick("/")} scroll={customScroll}>
                        Warehousing
                      </HashLink>
                    </li>
                    <li className="overlay-list-item">
                      <HashLink
                        className="dropdown-item"
                        to="/services#In-Plant Operations"
                        onClick={(e) => handleLinkClick("/")}
                        scroll={customScroll}
                      >
                        In-Plant Operations
                      </HashLink>
                    </li>
                    <li className="overlay-list-item">
                      <HashLink
                        className="dropdown-item"
                        to="/services#Last Mile & Distribution"
                        onClick={(e) => handleLinkClick("/")}
                        scroll={customScroll}
                      >
                        Last Mile & Distribution
                      </HashLink>
                    </li>
                    <li className="overlay-list-item">
                      <HashLink className="dropdown-item" to="/services#Packaging" onClick={(e) => handleLinkClick("/")} scroll={customScroll}>
                        Packaging
                      </HashLink>
                    </li>
                    <li className="overlay-list-item">
                      <HashLink className="dropdown-item" to="/services#E-Commerce" onClick={(e) => handleLinkClick("/")} scroll={customScroll}>
                        E-Commerce
                      </HashLink>
                    </li>
                    <li className="overlay-list-item">
                      <HashLink
                        className="dropdown-item"
                        to="/services#Project Management"
                        onClick={(e) => handleLinkClick("/")}
                        scroll={customScroll}
                      >
                        Project Management
                      </HashLink>
                    </li>
                    <li className="overlay-list-item">
                      <HashLink
                        className="dropdown-item"
                        to="/services#Supply Chain Consultancy"
                        onClick={(e) => handleLinkClick("/")}
                        scroll={customScroll}
                      >
                        Supply Chain Consultancy
                      </HashLink>
                    </li>
                  </ul>
                )}
              </li>
              {/* <li>
                <NavLink to="/">Projects</NavLink>
              </li> */}
              <li>
                <NavLink to="/news" onClick={() => handleLinkClick("/")}>
                  Blogs
                </NavLink>
              </li>
              <li>
                <HashLink className={getLinkClass("/faqs")} to="/#faq" onClick={() => handleLinkClick("/faqs")} scroll={customScroll}>
                  <span className="NavLink-text">FAQs</span>
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="bottom-bx">
            <div className="mail_call">
              <a className="mail" href="mailto:contact@dronalogitech.com">
                contact@dronalogitech.com
              </a>
              <a style={{ borderRight: "0" }} className="call" href="tel:+91 95993 11400">
                +91 95993 11400
              </a>
            </div>

            <div className="social">
              <h3>Follow us</h3>
              <ul>
                {/* <li>
                  <a target="_blank" href="/" className="you"></a>
                </li> */}
                <li>
                  <a target="_blank" href="https://www.linkedin.com/company/drona-logitech-pvt-ltd" className="you2"></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/dronalogitech/" className="you1"></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.facebook.com/profile.php?id=61553032583927&mibextid=LQQJ4d" className="you"></a>
                </li>
                {/* <li>
                  <a target="_blank" href="/" className="you3"></a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!------ OVERLAY MENU END ------> */}
    </header>
  );
};

export default HeaderComponent;
